import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Roboto Light";
        src: url("/fonts/Roboto-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Roboto Bold";
        src: url("/fonts/Roboto-Bold.ttf") format("truetype");
        font-style: normal;
    }

    body,
    html,
    a {
        font-family: 'Roboto Light', sans-serif;
    }

    body {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        background: #ffffff; /* White background */
        overflow-x: hidden;
    }

    a:hover {
        color: #003366; /* Blue text color on hover */
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: #f1f2f3; /* Light grey background */
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #003366 0px 0px 0px 1px; /* Blue border on focus */
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Roboto Bold', sans-serif;
        color: #003366; /* Blue text color */
        font-size: 56px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }

    p {
        color: #003366; /* Blue text color */
        font-size: 21px;        
        line-height: 1.41;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #003366; /* Blue text color */

        :hover {
            color: #70ad47; /* Green text color on hover */
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }
`;
