const routes = [
  // {
  //   path: ["/home", "/home"],
  //   exact: true,
  //   component: "Home",
  //   showHeader: true,
  //   showFooter: true,
  // },
  // {
  //   path: "/form",
  //   exact: true,
  //   component: "FormPage",  // Nome del componente della tua pagina del form
  //   showHeader: true,
  //   showFooter: true,
  // },
  {
    path: "/",
    exact: true,
    component: "ComingSoon",
    showHeader: false,
    showFooter: false,
  },
];

export default routes;
